import React from 'react';
import './App.css';
import QuestionAnswerPage from './pages/QuestionAnswerPage';
import HomePage from './pages/HomePage';

import { useKeycloak } from '@react-keycloak/web';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import ChatPage from './pages/ChatPage/ChatPage';

const router = createBrowserRouter([
  {
    path: "/",
    element: <HomePage></HomePage>
  },
  {
    path: "/ask",
    element: <QuestionAnswerPage></QuestionAnswerPage>
  },
  {
    path: "/chat",
    element: <ChatPage></ChatPage>
  },
])


function App() {
  const { keycloak, initialized } = useKeycloak();

  return (
    <div className="App">
      {/* <RouterProvider router={router}></RouterProvider> */}
      {!!keycloak.authenticated && <RouterProvider router={router}></RouterProvider>}
      {!keycloak.authenticated && <a href="#login" onClick={() => keycloak.login()}>Please log in</a>}
    </div>
  );
}

export default App;
