import { GridContainer } from "@trussworks/react-uswds";
import { FC, ReactElement } from "react";
import { Link } from "react-router-dom";

const HomePage: FC<any> = (): ReactElement => {
    return (<GridContainer>
        <ul>
            <li><Link to="/ask">Ask a Question</Link></li>
            <li><Link to="/chat">AI Chat</Link></li>
        </ul>
    </GridContainer>);
}

export default HomePage;