import { AI_MICROSERVICE_URL } from '../env';
import keycloak from '../keycloak';
import axios from 'axios';


export async function getQaConfigs() {
    return await axios.get(
        `${AI_MICROSERVICE_URL}/api/v1/ask`, {
            headers: {
                Authorization: `Bearer ${keycloak.token}`
            }
        });
}

export async function postQuestion(qaConfigId: string, question: string) {
    return await axios.post(
        `${AI_MICROSERVICE_URL}/api/v1/ask/${qaConfigId}/question`, {
        question: question
    }, {
        headers: {
            Authorization: `Bearer ${keycloak.token}`
        }
    });
}

export async function getChatConfigs() {
    return await axios.get(
        `${AI_MICROSERVICE_URL}/api/v1/chat`, {
            headers: {
                Authorization: `Bearer ${keycloak.token}`
            }
        }
    );
}

export async function deleteChatSession(chatSessionId: string) {
    return await axios.delete(
        `${AI_MICROSERVICE_URL}/api/v1/chat/session/${chatSessionId}`, {
            headers: {
                Authorization: `Bearer ${keycloak.token}`
            }
        }
    );
}

export async function createChatSession(chatConfigId: string, externalId: string|null) {
    return await axios.post(
        `${AI_MICROSERVICE_URL}/api/v1/chat/session`, {
            chatConfigId: chatConfigId,
            externalId: externalId
        }, {
            headers: {
                Authorization: `Bearer ${keycloak.token}`
            }
        }
    );
}

export async function getChatSessionMessages(chatSessionId: string) {
    return await axios.get(
        `${AI_MICROSERVICE_URL}/api/v1/chat/session/${chatSessionId}/message`, {
            headers: {
                Authorization: `Bearer ${keycloak.token}`
            }
        }
    );
}

export async function postChatSessionMessage(sessionId: string, message: any) {
    return await axios.post(
        `${AI_MICROSERVICE_URL}/api/v1/chat/session/${sessionId}/message`,
        message, {
            headers: {
                Authorization: `Bearer ${keycloak.token}`
            }
        }
    );
}