import { FC, FormEvent, ReactElement, useEffect, useId, useRef, useState } from "react";
import { createChatSession, deleteChatSession, getChatConfigs, getChatSessionMessages, postChatSessionMessage } from "../../services/AiMicroservice";
import { Button, Grid, GridContainer, Label, Select, TextInput } from "@trussworks/react-uswds";
import { Form } from "react-router-dom";
import "./ChatPage.css";
import Markdown from "react-markdown";


const SESSION_STATE = 0;
const CHAT_STATE = 1;


const ChatPage: FC<any> = (): ReactElement => {
    const id = useId();

    const [busy, setBusy] = useState(false);
    const [state, setState] = useState(SESSION_STATE);
    const [name, setName] = useState("Guest");
    const [chatConfigOptions, setChatConfigOptions] = useState<Array<ReactElement>>([]);
    const [selectedChatConfig, setSelectedChatConfig] = useState("");
    const [error, setError] = useState<string|null>(null);
    const [sessionId, setSessionId] = useState<string|null>();
    const [chatContents, setChatContents] = useState<Array<ReactElement>>([]);
    const [chatInput, setChatInput] = useState("");
    const chatRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await getChatConfigs();
                if (response.status === 200) {
                    const options = response.data.chatConfigs.content.map((c: any) => (
                        <option value={c.id} key={c.id}>{c.name}: {c.description}</option>
                    ));
                    const chatConfigId = response.data.chatConfigs.content[0].id;
                    setChatConfigOptions(options);
                    setSelectedChatConfig(chatConfigId);
                    setSessionId(localStorage.getItem("chat-session:" + chatConfigId));
                }
            }
            catch (error: any) {
                if (!!error.response) {
                    setError(`Error getting Chat Configs: ${error.response.data.message}`);
                }
                else {
                    setError(`Error getting Chat Configs: ${error.message}`);
                }
            }
        };

        fetchData();
    }, []);

    const appendChatMessage = async (item: ReactElement) => {
        await setChatContents((current) => [...current, item]);
    };

    const scrollChat = () => {
        chatRef.current?.scrollTo(0, chatRef.current?.scrollHeight);
    };

    const makeMessageElem = (role: string, name: string, content: string, published: Date) => {
        return (<div className="chat-message">
            <div>
                <strong className="text-primary">
                    {published.toLocaleString()} - {name}:
                </strong>
            </div>
            <Markdown className="chat-markdown">{content}</Markdown>
        </div>);
    }

    const submit = async (e: FormEvent) => {
        e.preventDefault();

        if (state == CHAT_STATE && !!sessionId) {
            const message = {
                messages: [
                    {
                        role: "USER",
                        name: name,
                        content: chatInput
                    }
                ]
            };

            await appendChatMessage(makeMessageElem("USER", name, chatInput, new Date()));
            setChatInput("");

            const response = await postChatSessionMessage(sessionId, message);
            const aiMessage = response.data;

            if (!!aiMessage.content) {
                await appendChatMessage(makeMessageElem("ASSISTANT", "AI", aiMessage.content, new Date(aiMessage.published)));
            }
        }
    };

    useEffect(() => {
        scrollChat();
    }, [chatContents]);

    const chatConfigChange = (e: any) => {
        const chatConfigId = e.target.value;

        setSelectedChatConfig(chatConfigId);
        setSessionId(localStorage.getItem("chat-session:" + chatConfigId));
    };

    const newSessionButton = async () => {
        const key = "chat-session:" + selectedChatConfig;
        const oldSessionId = localStorage.getItem(key);
        if (!!oldSessionId) {
            try {
                await deleteChatSession(oldSessionId);
            }
            catch (error: any) {
                console.log(error);
            }
        }

        const response: any = await createChatSession(selectedChatConfig, null);
        const newSession = response.data;

        localStorage.setItem(key, newSession.id);
        setSessionId(newSession.id);

        setState(CHAT_STATE);
        setChatContents([]);
    };

    const resumeSessionButton = async () => {
        const key = "chat-session:" + selectedChatConfig;
        const currentSessionId = localStorage.getItem(key);
        setSessionId(currentSessionId);

        if (!!currentSessionId) {
            setState(CHAT_STATE);

            const response = await getChatSessionMessages(currentSessionId);
            const messages = response.data;
            const messageElems = messages.map((m: any) => makeMessageElem(m.role, m.name, m.content, new Date(m.published)));

            await setChatContents(messageElems);
        }
    };

    const exitChatButton = async () => {
        setState(SESSION_STATE);
    };

    return (
        <GridContainer>
            <h1>Chat with AI</h1>
            <Form
                className="site-form"
                style={{paddingBottom:"1.5rem"}}
                onSubmit={submit}
            >
                <GridContainer>
                    { state == SESSION_STATE ? (<>
                        <Grid row gap>
                            <Grid desktop={{col:6}}>
                                <Label htmlFor={`chat-config-select${id}`}>Select a Topic:</Label>
                                <Select
                                    id={`chat-config-select${id}`}
                                    name="chat-config-select"
                                    disabled={busy}
                                    onChange={chatConfigChange}
                                    defaultValue={selectedChatConfig}
                                >
                                    { chatConfigOptions }
                                </Select>
                            </Grid>
                            <Grid desktop={{col:6}}>
                                <Label htmlFor={`chat-name${id}`}>Name:</Label>
                                <TextInput
                                    id={`chat-name${id}`}
                                    name="chat-name"
                                    type="text"
                                    defaultValue={name}
                                    onChange={(e) => setName(e.target.value)}
                                ></TextInput>
                            </Grid>
                        </Grid>
                        <br/>
                        <Grid row gap>
                            <Grid desktop={{col:6}}></Grid>
                            <Grid desktop={{col:12}}>
                                <Button
                                    type="button"
                                    onClick={newSessionButton}
                                >New Session</Button>
                                <Button
                                    disabled={!sessionId}
                                    type="button"
                                    onClick={resumeSessionButton}
                                >Resume Session</Button>
                            </Grid>
                        </Grid>
                    </>) : (<>
                        <Button
                            type="button"
                            onClick={exitChatButton}
                        >Exit Chat</Button>
                        <div className="chat-box" ref={chatRef}>
                            { chatContents }
                        </div>
                        <Grid row gap>
                            <Grid desktop={{col:12}}>
                                <TextInput
                                    id={`chat-input${id}`}
                                    name="chat-input"
                                    type="text"
                                    className="chat-input"
                                    value={chatInput}
                                    onChange={(e) => setChatInput(e.target.value)}
                                ></TextInput>
                            </Grid>
                        </Grid>
                    </>) }
                </GridContainer>
            </Form>
        </GridContainer>
    );
}

export default ChatPage;