import { Button, Fieldset, Form, FormGroup, GridContainer, Header, Label, Select, TextInput, Alert, Accordion, Grid } from "@trussworks/react-uswds";
import React, { FC, FormEvent, ReactElement, useEffect, useId, useState } from "react";
import { getQaConfigs, postQuestion } from "../../services/AiMicroservice";
import Markdown from "react-markdown";


const QuestionAnswerPage: FC<any> = (): ReactElement => {
  const id = useId();

  const [busy, setBusy] = useState(false);
  const [qaConfigOptions, setQaConfigOptions] = useState<Array<ReactElement>>([]);
  const [selectedQaConfig, setSelectedQaConfig] = useState("");
  const [question, setQuestion] = useState("");
  const [answer, setAnswer] = useState<any>(null);
  const [error, setError] = useState<string|null>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getQaConfigs();
        if (response.status === 200) {
          const options = response.data.askConfigs.content.map((c: any) => <option value={c.id} key={c.id}>{c.name}: {c.description}</option>);
          setQaConfigOptions(options);
          setSelectedQaConfig(response.data.askConfigs.content[0].id);
        }
      }
      catch (error: any) {
        if (!!error.response) {
          setError(`Error getting QA Configs: ${error.response.data.message}`);
        }
        else {
          setError(`Error getting QA Configs: ${error.message}`);
        }
      }
    };

    fetchData();
  }, []);

  const submitQuestion = async (e: FormEvent) => {
    setBusy(true);
    e.preventDefault();
    setError(null);
    setAnswer(null);
    try {
      const response = await postQuestion(selectedQaConfig, question);
      if (response.status === 200) {
        setAnswer(response.data);
      }
    }
    catch (error: any) {
      if (!!error.response) {
        setError(`Error getting answer: ${error.response.data.message}`);
      }
      else {
        setError(`Error getting answer: ${error.message}`);
      }
    }
    finally {
      setBusy(false);
    }
  };

  return (
    <GridContainer>
      <h1>Ask a Question, Get an Answer</h1>
      <Form
          onSubmit={submitQuestion}
          className="site-form"
          style={{paddingBottom:"1.5rem"}}>
        <GridContainer>
          <Grid row gap>
            <Grid desktop={{col:3}}>
              <Label htmlFor={`qa-config-select${id}`}>Select a Topic:</Label>
              <Select
                  id={`qa-config-select${id}`}
                  name="qa-config-select"
                  disabled={ busy }
                  onChange={(e) => setSelectedQaConfig(e.target.value)}>
                { qaConfigOptions }
              </Select>
            </Grid>
            <Grid desktop={{col:8}}>
              <Label htmlFor={`question${id}`}>Ask a Question:</Label>
              <TextInput
                id={`question${id}`}
                name="question"
                type="text"
                disabled={ busy }
                onChange={(e) => setQuestion(e.target.value)}>
              </TextInput>
            </Grid>
            <Grid desktop={{col:1}} style={{position:"relative"}}>
              <Button type="submit" disabled={ busy } style={{position:"absolute", bottom:"0", width:"100%"}}>Ask</Button>
            </Grid>
          </Grid>
        </GridContainer>
      </Form>
      { error &&
        <Alert type="error" heading="Error" headingLevel="h4">
          { error }
        </Alert>
      }
      { answer && 
        <div id={`answer${id}`}>
          <h2>{ answer.question }</h2>
          <div className="usa-prose site-prose">
            <Markdown>
              { answer.answer }
            </Markdown>
          </div>
          <h3>References</h3>
          { !!answer.searchResults ?
            <Accordion items={answer.searchResults.map((e: any) => 
              ({
                id: `${e.knowledgeDocumentId}:${e.startIndex}:${e.endIndex}`,
                // title: `${e.knowledgeDocumentName} (${e.knowledgeDocumentId}:${e.startIndex}-${e.endIndex})`,
                title: <>
                  { !!e.uri ? <a href={e.uri}>{ e.knowledgeDocumentName }</a> : e.knowledgeDocumentName }
                  {/* { ' (' }{ e.knowledgeDocumentId }:{ e.startIndex }-{ e.endIndex }{ ')' } */}
                  { ' (' }{ (100.0*e.startIndex/e.documentLength).toFixed(2) }% - { (100.0*e.endIndex/e.documentLength).toFixed(2) }%{ ')' }
                </>,
                // content: <div style={{whiteSpace:"pre-line"}}>{ e.text }</div>,
                content: <div className="usa-prose site-prose"><Markdown>{ e.text }</Markdown></div>,
                expanded: false,
                headingLevel: 'h4'
              })
            )}>
            </Accordion>
          : 'No references available' }
        </div>
      }
    </GridContainer>
  );
}

export default QuestionAnswerPage;
