import Keycloak from 'keycloak-js';
import { KEYCLOAK_URL } from './env';

const keycloak = new Keycloak({
    url: KEYCLOAK_URL,
    realm: "ai_microservice",
    clientId: "ai_microservice"
});

keycloak.onTokenExpired = async () => {
    if (!!keycloak.authenticated) {
        keycloak.updateToken(30)
            .catch(() => console.log("Failed to update token"));
    }
};

export default keycloak;
